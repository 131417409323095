import { useState } from "react";

export default function About() {
  const services = [
    { title: "Web Development", description: "We build responsive, scalable web solutions tailored to your needs." },
    { title: "UI/UX Design", description: "Our design team creates intuitive and engaging user experiences." },
    { title: "Web Hosting", description: "Reliable, secure web hosting solutions to keep your site running." },
    { title: "Cloud Solutions", description: "We offer cloud-based services for seamless scalability and efficiency." },
    { title: "Mobile Development", description: "Custom mobile app development for iOS and Android platforms." },
    { title: "QA & Testing", description: "Ensuring software quality with thorough testing methodologies." },
    { title: "API Integration", description: "Seamlessly connect your applications with powerful API solutions." },
    { title: "Database Modeling", description: "Designing efficient database structures for optimal performance." }
  ];
  
  const [flipped, setFlipped] = useState(Array(services.length).fill(false));
  
  const toggleFlip = (index) => {
    setFlipped(prev => {
      const newFlip = [...prev];
      newFlip[index] = !newFlip[index];
      return newFlip;
    });
  };

  return (
    <section className="bg-transparent bsb-hero-1 px-3 min-vh-100 d-flex flex-column justify-content-center" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
            <h2 className="mb-4 display-5 text-light" style={{ paddingTop: '10px' }}>Our Expertise</h2>
            <p className="fs-5 text-light mb-5">Our clients are our top priority, and we are committed to providing them with the highest level of service.</p>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-light-subtle" />
          </div>
        </div>
      </div>

      <div className="container overflow-hidden mb-5">
        <div className="row row-cols-2 row-cols-md-4 g-4">
          {services.map((service, index) => (
            <div key={index} className="col" onClick={() => toggleFlip(index)}>
              <div className={`flip-card ${flipped[index] ? 'flipped' : ''}`}>
                <div className="flip-card-inner">
                  <div className="flip-card-front d-flex align-items-center justify-content-center text-light px-4 py-3">
                    <h3 className="text-center">{service.title}</h3>
                  </div>
                  <div className="flip-card-back d-flex align-items-center justify-content-center text-light px-4 py-3">
                    <p className="m-0">{service.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .flip-card {
          perspective: 1000px;
          cursor: pointer;
        }
        .flip-card-inner {
          width: 100%;
          height: 150px;
          position: relative;
          transition: transform 0.6s;
          transform-style: preserve-3d;
        }
        .flipped .flip-card-inner {
          transform: rotateY(180deg);
        }
        .flip-card-front, .flip-card-back {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background-color: transparent;
        }
        .flip-card-back {
          transform: rotateY(180deg);
        }
      `}</style>
    </section>
  );
}
