import React from 'react';
import { NavLink } from 'react-router-dom';
import './App.css'; // Import your CSS file

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-transparent text-dark">
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand underline text-light hover-outline">DNG</NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className="nav-item">
  <a 
    href="#about" 
    className="nav-link text-light" 
    style={{ transition: 'opacity 0.2s' }} 
    onMouseEnter={(e) => e.target.style.opacity = 0.7} 
    onMouseLeave={(e) => e.target.style.opacity = 1}
  >
    About
  </a>
</li>


<li className="nav-item">
  <a 
    href="#contact" 
    className="nav-link text-light" 
    style={{ transition: 'opacity 0.2s' }} 
    onMouseEnter={(e) => e.target.style.opacity = 0.7} 
    onMouseLeave={(e) => e.target.style.opacity = 1}
  >
    Contact
  </a>
</li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
