import React from 'react';
import './Preloader.css'; // Add your CSS here

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="spinner">Hello</div>
    </div>
  );
};

export default Preloader;
