import React from 'react';
import { Link } from 'react-router-dom';
import hero from './hero.webp'; // Assuming you are using this image somewhere
import Navbar from './Navbar';


function Home() {
  return (
    <section
      id="home"
      className="bg-transparent bsb-hero-1 px-3 vh-100" // Removed bsb-overlay and bsb-hover-pull
    >
      <Navbar />

      <div className="container h-100">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7 text-center text-light"> {/* Changed text-light to text-dark */}
          <h1 className="display-3 fw-bold mb-3">
  D<span className="rotate-n">N</span>G Solutions
</h1>
            <p className="lead mb-5">Welcome to DNG Solutions, an IT solutions and consulting firm</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
  {/* Free Consultation button with rounded edges */}
  <a 
    href="#contact" 
    className="btn bsb-btn-3xl btn gap-3 rounded-pill" 
    style={{ backgroundColor: 'black', color: 'white', borderRadius: '50px', padding: '10px 25px' }}
  >
    Free Consultation
  </a>

  {/* Learn More button with rounded edges */}
  <a 
    href="#about" 
    className="btn bsb-btn-3xl btn-outline-light no-hover animated-button rounded-pill"
    style={{
      color: "white",
      backgroundColor: "transparent",
      border: "2px solid white",
      position: "relative",
      textDecoration: "none",
      overflow: "hidden",
      borderRadius: "50px",
      padding: "10px 25px",
    }}
  >
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    Learn More
  </a>
</div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
