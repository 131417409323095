import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Navbar from './Navbar';
import Footer from './Footer';
import './App.css'; // Ensure you import your CSS file

function Contact() {
  const form = useRef();
  const [sent, setSent] = useState(false);
  const [isSending, setIsSending] = useState(false); // Track sending state
  // State for cursor position
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  // Effect to handle mouse movement
  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    };
    
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
  
    if (isSending) return; // Prevent multiple submissions
  
    setIsSending(true); // Disable button
    setSent(""); // Clear previous message
  
    emailjs
      .sendForm("service_9bxq12r", "template_s951z1d", form.current, "gSKiaJwNqZH8Te9Mt")
      .then(
        () => {
          console.log("SUCCESS!");
          setSent("Message has been sent");
  
          // Reset form fields after successful submission
          form.current.reset();
  
          // Re-enable button after 5 seconds
          setTimeout(() => {
            setIsSending(false);
            setSent(""); // Optional: Clear success message after timeout
          }, 5000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setSent("Failed to send message. Try again.");
          setIsSending(false); // Re-enable button immediately on error
        }
      );
  };
  

  return (
    <div>
    <div className="bg-transparent mt-5">
    <section className="bg-transparent py-3 py-md-5 vh-100" id="contact">
    <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center text-light">Contact</h2>
              <p className="text-secondary mb-2 text-center text-light mb-5">
                Please fill out the form below & we will get back to you as soon as possible.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-light-subtle" />
              </div>
          </div>
        </div>
  
        <div className="container">
          <div className="row justify-content-lg-center">
            <div className="col-12 col-lg-9">
              <div
                className="border rounded shadow-sm overflow-hidden"
           
              >
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row gy-4 gy-xl-5 p-4 p-xl-5 bg-transparent">
                  <div className="col-12 text-light">
  <label htmlFor="fullname" className="form-label">
    Name <span className="text-danger">*</span>
  </label>
  <input
    type="text"
    className="form-control text-light"
    id="fullname"
    name="name"
    required
    style={{
      backgroundColor: 'transparent',
      borderColor: '#ccc', // Optional: Adjust the border color if needed
    }}
  />
</div>

                    <div className="col-12 col-md-6">
  <label htmlFor="email" className="form-label text-light">
    Email <span className="text-danger">*</span>
  </label>
  <div className="input-group">
    <span className="input-group-text" style={{ backgroundColor: 'transparent' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-envelope text-light"
        viewBox="0 0 16 16"
      >
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
      </svg>
    </span>
    <input
      type="email"
      className="form-control text-light"
      id="email"
      name="email"
      required
      style={{
        backgroundColor: 'transparent',
        borderColor: '#ccc', // Optional: Adjust the border color if needed
      }}
    />
  </div>
</div>

                    <div className="col-12 col-md-6 text-light">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                      <span className="input-group-text" style={{ backgroundColor: 'transparent' }}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-telephone text-light"
    viewBox="0 0 16 16"
  >
    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
  </svg>
</span>

                        <input
  type="tel"
  className="form-control text-light"
  id="phone"
  name="phone"
  style={{
    backgroundColor: 'transparent',
    borderColor: '#ccc', // You can customize the border color if necessary
  }}
/>

                      </div>
                    </div>
                    <div className="col-12 text-light">
  <label htmlFor="message" className="form-label">
    Message <span className="text-danger">*</span>
  </label>
  <textarea
    className="form-control text-light"
    id="message"
    name="message"
    rows="4"
    required
    style={{
      backgroundColor: 'transparent',
      borderColor: '#ccc', // You can customize the pnavzborder color if necessary
    }}
  ></textarea>
</div>

                    <div className="col-12">
                      <button type="submit" className="btn btn-success">
                        Send Message
                      </button>
                    </div>
                    {sent && <b className="text-success mt-3">{sent}</b>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  
  );
}

export default Contact;
