import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Home';
import Projects from './Projects';
import About from './About';
import Contact from './Contact';
import Login from './login';
import Ad from './ad';
import Navbar from './Navbar';
import Footer from './Footer';
import dng from './background-1.jpeg';
import Preloader from './Preloader';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation(); // Now inside Router
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show loading screen only on initial load
    const isInitialLoad = sessionStorage.getItem('isInitialLoad') === null;

    if (isInitialLoad) {
      sessionStorage.setItem('isInitialLoad', 'false');
      setLoading(true);
      const timer = setTimeout(() => setLoading(false), 1000);
      return () => clearTimeout(timer);
    }

    setLoading(false);
  }, [location]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <div style={{ position: "relative", minHeight: "100vh" }}>
          {/* Fixed background */}
          <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "linear-gradient(45deg, #000000, #666666, #a29d9d, #000000)", 
      backgroundSize: "cover",
      backgroundPosition: "center",
      zIndex: -1,
    }}
    
        
          />

          {/* Content container */}
          <div style={{ position: "relative", zIndex: 1 }}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Home />
                    <About />
                    <Contact />
                    <Footer />
                  </>
                }
              />
              {/* Add other routes here */}
            </Routes>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
